<template>
  <div
    class="new-shipment-acceptation-payment-amount tw-flex tw-flex-col md:tw-flex-row tw-mb-4 md:tw-mb-8"
  >
    <div
      :class="{
        'new-shipment-acceptation-payment-amount--disabled': expired,
        'tw-w-1/2': expired && hasRenew
      }"
      data-test="amount"
    >
      <div
        v-text="label"
        class="tw-text-gray-700 tw-text-sm tw-font-medium tw-whitespace-nowrap"
        data-test="label"
      />
      <div
        class="new-shipment-acceptation-payment-amount__value tw-text-blue-500 tw-text-2xl tw-flex tw-leading-tight"
      >
        <div
          v-if="initialAmount !== null"
          v-text="$options.filters.currency(initialAmount, currency, $i18n.locale)"
          class="tw-line-through tw-mr-6"
          data-test="initial-value"
        />
        <div
          v-text="$t('price_incl_tax', {
            price: $options.filters.currency(amount, currency, $i18n.locale)
          })"
          data-test="value"
        />
      </div>
    </div>
    <div
      v-if="expired && hasRenew"
      class="tw-w-1/2 tw-flex tw-items-end"
    >
      <UiLink
        href="#"
        class="tw-mt-3 md:tw-mt-0 md:tw-ml-auto"
        v-text="$t('new-shipment.labels.quotation_request.renew_price')"
        data-test="renew"
        @click.prevent="$emit('renew')"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentAcceptationPaymentAmount
   * @param {string} label
   * @param {number} amount
   * @param {number} [initialAmount=null] - A second amount displayed in line-through as
   * a "previous" amount.
   * @param {string} currency
   * @param {boolean} [expired=false]
   * @param {boolean} [hasRenew=true] - Specify if the renew button should be displayed
   * if its expired.
   */
  export default {
    props: {
      label: {
        type: String,
        required: true
      },
      amount: {
        type: Number,
        required: true
      },
      initialAmount: {
        type: Number,
        default: null
      },
      currency: {
        type: String,
        required: true
      },
      expired: {
        type: Boolean,
        default: false
      },
      hasRenew: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-acceptation-payment-amount--disabled {
  opacity: 0.75;
}
.new-shipment-acceptation-payment-amount--disabled .new-shipment-acceptation-payment-amount__value {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}
</style>
