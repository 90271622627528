<template>
  <button
    :title="holderName"
    :class="{
      'stripe-card-item--active': active
    }"
    type="button"
    class="stripe-card-item tw-flex tw-items-center tw-bg-white tw-px-3 tw-py-2 tw-text-left"
  >
    <stripe-card-item-card
      :holder-name="holderName"
      :last-four="lastFour"
      :expires-at="expiresAt"
      :type="type"
      data-test="card"
    >
      <template #prefix>
        <ui-material-icon
          :name="active ? 'check_circle' : 'radio_button_unchecked'"
          class="stripe-card-item__radio tw-text-xl tw-mr-4"
          data-test="radio"
        />
      </template>
    </stripe-card-item-card>
  </button>
</template>

<script>
  import StripeCardItemCard from './_subs/StripeCardItemCard/index.vue'

  /**
   * @module component - StripeCardItem
   * @param {string} holderName
   * @param {number} lastFour
   * @param {string} expiresAt
   * @param {string} type
   * @param {boolean} [active=false]
   */
  export default {
    inheritAttrs: true,
    name: 'StripeCardItem',
    components: {
      StripeCardItemCard
    },
    props: {
      holderName: {
        type: String,
        required: true
      },
      lastFour: {
        type: [Number, String],
        required: true
      },
      expiresAt: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
.stripe-card-item:disabled img {
  filter: grayscale(1) brightness(1.2);
}
.stripe-card-item {
  min-height: 42px;
  border: 1px solid $gray;
  transition: border 200ms, background-color 200ms;
}
.stripe-card-item--active:not(:disabled), .stripe-card-item:focus:not(:disabled), .stripe-card-item.aria-focus-active {
  border: 1px solid $info;
}
.stripe-card-item--active:not(:disabled) .stripe-card-item__radio {
  color: $info;
}
.stripe-card-item__radio {
  color: $gray;
  transition: color 200ms;
}
.stripe-card-item:hover:not(:disabled) {
  background-color: $light-gray;
}
.stripe-card-item:hover:not(:disabled):not(.stripe-card-item--active) .stripe-card-item__radio, .stripe-card-item:disabled .stripe-card-item__radio {
  color: $secondary-text;
}
.stripe-card-item:disabled {
  cursor: not-allowed;
  background-color: rgba(black, 0.05);
  color: rgba(0, 0, 0, 0.54);
}
.stripe-card-item:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
</style>
