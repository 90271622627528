<template>
  <div
    class="shipment-payment-view-notice tw-flex tw-items-center tw-bg-green-100 tw-relative tw-text-xs tw-leading-snug tw-py-2 tw-mt-10 tw-mb-5 tw-pr-3 tw-text-secondary-text"
  >
    <ui-ctk-icon
      name="info"
      class="tw-text-blue-500 tw-mr-1"
      data-test="icon"
    />
    <p
      class="tw-flex-1 tw-mb-0"
      v-text="notice"
      data-test="paragraph"
    />
  </div>
</template>

<script>
  /**
   * @module component - ShipmentPaymentViewNotice
   * @param {string} notice
   */
  export default {
    name: 'ShipmentPaymentViewNotice',
    props: {
      notice: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.shipment-payment-view-notice .ctk-font {
  font-size: 2.7rem;
}
.shipment-payment-view-notice::after {
  position: absolute;
  content: '';
  left: 2.6rem;
  width: 0;
  height: 0;
  bottom: -9px;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: $primary-lightest transparent transparent transparent;
}
</style>
